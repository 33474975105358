// src/pages/Home.js

import React, { useState, useEffect } from 'react';
import AboutUs from '../components/AboutUs';
import CountUpAnimation from '../components/Counter';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../css/Home.css'; // Your custom CSS file
import photo from '../photos/1.PORTRAIT.jpg'; // Import your solitary image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaneDeparture, faPassport, faBed } from '@fortawesome/free-solid-svg-icons';



function Home() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const image = new Image();
        image.onload = () => {
            setLoading(false); // Set loading to false when the image is loaded
        };
        image.src = photo;

        // Cleanup function to remove event listener
        return () => {
            image.onload = null;
        };
    }, []);

    return (
        <>
            <div>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <div>
                        <div className="wrapper">
                            <CountUpAnimation
                                iconComponent={<FontAwesomeIcon icon={faPlaneDeparture} />}
                                initialValue={0}
                                targetValue={66}
                                text="Flights Taken"
                            />
                            <CountUpAnimation
                                iconComponent={<FontAwesomeIcon icon={faBed} />}
                                initialValue={0}
                                targetValue={156}
                                text="Beds Slept In"
                            />
                            <CountUpAnimation
                                iconComponent={<FontAwesomeIcon icon={faPassport} />}
                                initialValue={0}
                                targetValue={48}
                                text="Countries Visited"
                            />
                        </div>
                        <div className="flex-container">
                            <img
                                src={photo}
                                alt="Jeff and Bree"
                                className="centered-image" // Applying a custom class for centering
                            />
                        </div>
                    </div>
                )}
                <AboutUs />
            </div>
        </>
    );
}

export default Home;
