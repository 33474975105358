import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import '../css/AboutUs.css'
import ReactGA from "react-ga";

function AboutUs() {
    const handleClick = () => {
        // Track button click event
        ReactGA.event({
            category: 'Button Click',
            action: 'Clicked YouTube Button',
            label: 'About Us Page'
        });
    };

    return (
        <Card className="text-center about-us">
            <Card.Header />
            <Card.Body>
                <Card.Title>About Us</Card.Title>
                <Card.Text>
                    Ahoy! We're Jeff & Bree!
                    <br/> <br/>
                    In June of 2022, we left our corporate software careers, sold everything we own, and departed for a 2 year adventure around the globe with the goal of living a onebag minimal life and crossing things off our bucket list (and some other cool things along the way).
                    <br/> <br/>
                    Thanks for stopping by and if you want to see more of our adventures click on the button below or subscribe to us on Instagram!
                </Card.Text>
                <Button variant="primary" href={"https://www.youtube.com/@onebagoneworld?sub_confirmation=1"} target="_blank" onClick={handleClick}>Check out our vlogs on YouTube!</Button>
            </Card.Body>
            <Card.Footer />
        </Card>
    );
}

export default AboutUs