import React from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';

const BlogPostCard = ({ title, content, trackLinkClick }) => {
    return (
        <Card className="text-center">

            <Card.Body>
                {content}
            </Card.Body>
            <Card.Footer />
        </Card>
    );
};

BlogPostCard.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    trackLinkClick: PropTypes.func.isRequired,
};

export default BlogPostCard;
