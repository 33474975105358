import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
// Components
import NavigationBar from './components/NavigationBar';
// Pages
import Home from './pages/Home';
import PackingLists from './pages/PackingLists';
import ContactUs from "./pages/Contact";
// import ComingSoon from "./pages/ProductReviews";
import BlogPost from "./pages/trips_and_tricks/credit_cards";
import OspreyDayliteReview from "./pages/review_posts/osprey_daylite";
import HotWeatherShirts from "./pages/review_posts/hot_weather_shirts"
import Footer from "./components/Footer";
import ReactGA from 'react-ga';

function App() {
    // Initialize Google Analytics
    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    }, []);

    // Track page view on route change
    const TrackPageViews = () => {
        const location = useLocation();

        useEffect(() => {
            ReactGA.pageview(location.pathname + location.search);
        }, [location]);

        return null;
    };

    return (
        <>
            <Router>
                <TrackPageViews />
                <div>
                    <NavigationBar />
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route path="/product-reviews/osprey-daylite-26" element={<OspreyDayliteReview />} />
                        <Route path="/product-reviews/hot-weather-shirts" element={<HotWeatherShirts />} />
                        <Route path="/packing-lists" element={<PackingLists />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route path="/tips-and-tricks/credit-cards" element={<BlogPost />} />
                    </Routes>
                </div>
            </Router>
            <Footer/>
        </>
    );
}

export default App;
