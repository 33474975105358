import React, { useState, useEffect, useCallback } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner'; // Import the Spinner component
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import 'bootstrap/dist/css/bootstrap.min.css';
import YouTube from 'react-youtube'; // Import the YouTube component
import '../App.css';
import '../css/PackingLists.css'

function PackingLists() {
    const [activeTab, setActiveTab] = useState('brees-one-bag');
    const [isLoading, setIsLoading] = useState(false);
    const [scriptsLoaded, setScriptsLoaded] = useState({});
    const location = useLocation(); // Use useLocation hook to access the current location

    useEffect(() => {
        // Update the active tab based on the hash part of the URL when the component mounts
        const hash = location.hash.substring(1); // Remove the '#' from the hash
        setActiveTab(hash || 'brees-one-bag'); // Set activeTab to the hash or 'brees-one-bag' if hash is empty
    }, [location]); // Run the effect whenever the location changes

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
        loadScript(tabId);
    };

    const loadScript = useCallback((tabId) => {
        setIsLoading(true); // Set isLoading to true when the script starts loading

        if (!scriptsLoaded[tabId]) {
            const script = document.createElement('script');
            script.src = tabId === 'brees-one-bag' ? "https://lighterpack.com/e/yp9z1m" : "https://lighterpack.com/e/usqwfv";
            script.onload = () => {
                setIsLoading(false); // Set isLoading to false when the script has finished loading
            };
            const container = document.getElementById(tabId);

            // Check if the script already exists in the container
            if (!container.querySelector(`script[src="${script.src}"]`)) {
                container.appendChild(script);
                // Mark the script as loaded
                setScriptsLoaded({ ...scriptsLoaded, [tabId]: true });
            }
        }
    }, [scriptsLoaded]);

    useEffect(() => {
        // Load script for the active tab when the component mounts or when activeTab changes
        loadScript(activeTab);
    }, [activeTab, loadScript]);

    return (
        <>
            <div className="packing-container mt-5 mb-5">
                <Tabs
                    id="packing-lists-tabs"
                    activeKey={activeTab}
                    onSelect={handleTabChange}
                    className="mb-3"
                >
                    <Tab eventKey="brees-one-bag" title="Hers">
                        <div className={`tab-pane fade ${activeTab === 'brees-one-bag' ? 'show active' : ''}`} id="brees-one-bag">
                            <div className="video-container">
                                <YouTube videoId="GbzpZZ-e60w" />
                            </div>
                            <div className={"embedded-script-container"} id="yp9z1m" />
                        </div>
                    </Tab>
                    <Tab eventKey="jeffs-one-bag" title="His">
                        <div className={`tab-pane fade ${activeTab === 'jeffs-one-bag' ? 'show active' : ''}`} id="jeffs-one-bag">
                            <div className="video-container">
                                <YouTube videoId="sD2QfEzqXpk" />
                            </div>
                            <div className={"embedded-script-container"} id="usqwfv" />
                        </div>
                    </Tab>
                </Tabs>
                {isLoading && <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>}
            </div>
            <Card className="text-center mb-3 affiliate-disclaimer">
                <Card.Body>
                    <Card.Text className="text-muted">
                        <em>These links are affiliate links, which help support the page at no additional cost to you.</em>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default PackingLists;
