import ReactGA from 'react-ga';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import '../css/Footer.css'; // Import custom CSS file for styling

function Footer() {
    const trackButtonClick = (category, action) => {
        ReactGA.event({
            category: category,
            action: action,
            label: 'Footer'
        });
    };

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-content">
                    {/* First flex container for copyright */}
                    <div className="copyright-container">
                        <p className="copyright">© {new Date().getFullYear()} One Bag One World.<br/>All rights reserved.</p>
                    </div>

                    {/* Second flex container for social links */}
                    <div className="social-links-container">
                        <div className="social-links">
                            <a href="https://www.youtube.com/@onebagoneworld?sub_confirmation=1"
                               target="_blank"
                               rel="noopener noreferrer"
                               className="youtube"
                               onClick={() => trackButtonClick('Social Media', 'Clicked YouTube')}>
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                            <a href="https://www.instagram.com/onebagoneworldtravel"
                               target="_blank"
                               rel="noopener noreferrer"
                               className="instagram"
                               onClick={() => trackButtonClick('Social Media', 'Clicked Instagram')}>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                    </div>

                    {/* Third flex container for Buy Me a Coffee button */}
                    <div className="coffee-link-container">
                        <div className="coffee-link">
                            <a href="https://www.buymeacoffee.com/onebagoneworld"
                               target="_blank"
                               rel="noopener noreferrer"
                               onClick={() => trackButtonClick('Buy Me a Coffee', 'Clicked Buy Me a Coffee')}>
                                <img
                                    alt="Support our page"
                                    src="https://img.buymeacoffee.com/button-api/?text=Buy us a coffee&emoji=🛫&slug=onebagoneworld&button_colour=ea8686&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
