import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
// import { ReCAPTCHA } from "react-google-recaptcha";
import '../css/ContactForm.css';
import { Form, Button } from "react-bootstrap";

export const ContactUs = () => {
    const form = useRef();
    // const refCaptcha = useRef();
    const [emailSent, setEmailSent] = useState(false);

    const sanitizeInput = (input) => {
        // Use a regular expression to remove any potentially dangerous characters
        return input.replace(/(<([^>]+)>)/gi, "");
    };

    const sendEmail = (e) => {
        e.preventDefault();

        const name = sanitizeInput(e.target.name.value);
        const email = sanitizeInput(e.target.email.value);
        const inquiry = sanitizeInput(e.target.inquiry.value);

        const params = {
            'name': name,
            'email': email,
            'inquiry': inquiry
        };

        emailjs
            .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                form.current,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
                params
            )
            .then(
                () => {
                    console.log('SUCCESS!');
                    setEmailSent(true);
                    e.target.reset();
                },
                (error) => {
                    console.error('FAILED...', error);
                }
            );
    };

    return (
        <div className="contact-us-container">
            {!emailSent ? (
                <Form onSubmit={sendEmail} name="contact-form" ref={form}>
                    <Form.Group controlId="formBasicName" className="form-group name-group">
                        <Form.Label>Your Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail" className="form-group email-group">
                        <Form.Label>Your Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicInquiry">
                        <Form.Label>Your Message</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="inquiry"
                            placeholder="Enter your message"
                            required
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Contact Us
                    </Button>
                </Form>
            ) : (
                <p className="thank-you-message">
                    Thank you for sending us a message. We will get back to you as soon as possible!
                </p>
            )}
            <p className="disclaimer">
                We will never share your information with anyone.
            </p>
        </div>
    );
}

export default ContactUs;
