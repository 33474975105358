import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Spin as Hamburger } from 'hamburger-react';
import logo from '../images/logo512.png';
import '../css/NavigationBar.css';

function NavigationBar() {
    const [isOpen, setOpen] = useState(false);

    const handleNavLinkClick = (tabId) => {
        window.location.hash = `#${tabId}`;
    };

    return (
        <>
            <Navbar expand="lg" className="justify-content-center navbar-container">
                <div className="container">
                    <Navbar.Brand as={Link} to="/" className="mr-auto">
                        <img src={logo} alt="Logo" className="logo mr-2" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggler">
                        <Hamburger toggled={isOpen} toggle={setOpen} hideOutline={true} color="#7CBFC8" />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" className={isOpen ? 'show' : ''}>
                        <Nav className="ml-auto">
                            <NavDropdown title="PACKING LISTS" id="packing-nav-dropdown" className="nav-dropdown">
                                <NavDropdown.Item as={Link} to="/packing-lists#brees-one-bag" onClick={() => handleNavLinkClick('brees-one-bag')}>HER One Bag</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/packing-lists#jeffs-one-bag" onClick={() => handleNavLinkClick('jeffs-one-bag')}>HIS One Bag</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="TIPS & TRICKS" id="tips-nav-dropdown" className="nav-dropdown">
                                <NavDropdown.Item as={Link} to="/tips-and-tricks/credit-cards" onClick={() => handleNavLinkClick('packing-tips')}>Credit Cards</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="REVIEWS" id="reviews-nav-dropdown" className="nav-dropdown">
                                <NavDropdown.Item as={Link} to="/product-reviews/osprey-daylite-26" onClick={() => handleNavLinkClick('osprey-daylite')}>Osprey Daylite 26+6</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/product-reviews/hot-weather-shirts" onClick={() => handleNavLinkClick('hot-weather-shirts')}>Hot Weather Shirts</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link as={Link} to="/contact-us" className="nav-link">CONTACT</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            <div className="white-space-padding" />
        </>
    );
}

export default NavigationBar;
