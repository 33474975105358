import ReactGA from 'react-ga';
import React from 'react';
import Card from 'react-bootstrap/Card';
import BlogPostCard from "../../components/BlogPostCard";
import '../../css/BlogPosts.css'
import ChaseInkBusinessPreferredImg from '../../images/ink_preferred_card.png'
import ChaseSapphirePreferredImg  from '../../images/sapphire_preferred_card.png'
import YouTube from "react-youtube";

const trackLinkClick = () => {
    ReactGA.event({
        category: 'Link Click',
        action: 'Clicked Referral Link',
        label: 'Blog Post'
    });
};

const BlogPost = () => {
    return (
        <div className="container">
            <BlogPostCard
                title="Unlocking Your Dream Getaway: The Power of Credit Card Points"
                trackLinkClick={trackLinkClick}
                content={
                    <div>
                        <div className="credit-card-container">
                            <div className="credit-card">
                                <a href="https://www.referyourchasecard.com/21q/3JS5U6WJS3"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   onClick={trackLinkClick}>
                                    <img src={ChaseInkBusinessPreferredImg} alt="Chase Ink Business Preferred" className="credit-card-image" />
                                </a>
                                <p className="italic-text">Current Signup Bonus: 100,000 points</p>
                            </div>
                            <div className="credit-card">
                                <a href="https://www.referyourchasecard.com/19m/KLFZOZQMSR"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   onClick={trackLinkClick}>
                                    <img src={ChaseSapphirePreferredImg} alt="Chase Sapphire Preferred" className="credit-card-image" />
                                </a>
                                <p className="italic-text">Current Signup Bonus: 75,000 points</p>
                            </div>
                        </div>
                        <Card.Title><h1>Unlocking Your Dream Getaway:<br/>The Power of Credit Card Points</h1></Card.Title>
                        <Card.Text>
                            <div className="blog-post">
                                <p>
                                    Are you dreaming of jetting off to exotic locales, exploring far-flung destinations, or simply indulging in a luxurious vacation? While the cost of travel can sometimes feel daunting, there's a secret weapon in your financial arsenal that can make those dreams a reality: credit card points.
                                </p>
                                <p>
                                    One standout card in this arena is the Chase Sapphire Preferred, a game-changer for savvy travelers. With a generous signup bonus of 60,000 points, earned after spending $4,000 within the first three months of opening the account, the Chase Sapphire Preferred opens doors to a world of possibilities.
                                </p>
                                <p>
                                    So, why should you consider harnessing the power of credit card points, specifically with the Chase Sapphire Preferred? Let's dive into the perks:
                                </p>
                                <ul>
                                    <li>
                                        <strong>Unlocking Luxury for Less:</strong> Ever imagined staying in a lavish 5-star resort in Bali or enjoying a beachfront villa in the Maldives? With the Chase Sapphire Preferred, those dreams can become a reality. The 60,000-point signup bonus alone could potentially cover 12 nights at a top-tier hotel in Bali, allowing you to experience luxury without breaking the bank.
                                    </li>
                                    <li>
                                        <strong>Comprehensive Travel Protections:</strong> When you're globe-trotting, peace of mind is priceless. The Chase Sapphire Preferred offers robust travel benefits, including rental car collision damage waiver (CDW) protection and trip cancellation/interruption insurance. Say goodbye to worries about mishaps during your travels, knowing that you're covered by these valuable protections.
                                    </li>
                                    <li>
                                        <strong>Flexible Redemption Options:</strong> Not all credit card rewards programs are created equal, but Chase Ultimate Rewards® stands out for its flexibility. With the Chase Sapphire Preferred, you have the option to redeem points for travel through the Chase Ultimate Rewards portal at a boosted value, or you can transfer points to a variety of airline and hotel partners. This means you can maximize the value of your points and tailor your rewards to suit your travel preferences.
                                    </li>
                                    <li>
                                        <strong>Access to Exclusive Transfer Partners:</strong> Speaking of transfer partners, the Chase Sapphire Preferred boasts an impressive lineup, including major airlines and hotel chains. Whether you're eyeing a first-class flight with Singapore Airlines or a stay at a Marriott resort, you'll find ample opportunities to stretch your points further and enjoy elite travel experiences.
                                    </li>
                                </ul>
                                <p>
                                    In essence, the Chase Sapphire Preferred isn't just a credit card—it's a gateway to unforgettable adventures and incredible savings. With its enticing signup bonus, comprehensive travel protections, and flexible redemption options, it's a must-have for anyone with wanderlust in their heart.
                                </p>
                                <p>
                                    <em> Watch our YouTube video where we discuss how we used Chase points to plan an unforgettable trip to the Maldives </em>
                                    <div className="video-container">
                                        <YouTube videoId="MHEVNKQTr28" />
                                    </div>
                                </p>
                                <p>
                                    Already have a Chase Sapphire Preferred? Consider expanding your rewards arsenal by adding the Chase Ink Business Preferred card to your wallet. With its own impressive signup bonus and tailored benefits for business spending, it's the perfect complement to your travel rewards strategy. Click <a href="https://www.referyourchasecard.com/21q/3JS5U6WJS3"
                                                                                                                                                                                                                                                                                                                                             target="_blank"
                                                                                                                                                                                                                                                                                                                                             rel="noopener noreferrer"
                                                                                                                                                                                                                                                                                                                                             onClick={trackLinkClick}>here</a> to apply.
                                </p>
                                <p>
                                    Ready to embark on your next journey? Don't miss out on this opportunity to supercharge your travels with the Chase Sapphire Preferred. Click <a href="https://www.referyourchasecard.com/19m/KLFZOZQMSR"
                                                                                                                                                                                     target="_blank"
                                                                                                                                                                                     rel="noopener noreferrer"
                                                                                                                                                                                     onClick={trackLinkClick}>here</a> to apply and start turning your travel dreams into reality. Happy travels!
                                </p>
                                <p>
                                    <em>[Disclaimer: This article contains referral links. We may receive compensation if you apply through these links. However, we only recommend products and services that we truly believe in and have personally vetted.]</em>
                                </p>
                            </div>
                        </Card.Text>
                    </div>
            }
        />
        </div>
    );
};

export default BlogPost;